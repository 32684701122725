* {
  box-sizing: border-box;
}

body {
  margin: 0;
  min-height: 100vh;
}

#root {
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.warning-msg {
  width: 30vw;
  font-size: 1.1rem !important;
  color: red !important;
  text-transform: none !important;
  font-weight: bold;
  line-height: 1.5;
}

.transparent-btn {
  background-color: transparent !important;
  border: none;
  color: white;
  padding: 0 !important;
  display: grid;
  place-items: center;
}

.m-auto {
  margin: auto;
}

.txt-center {
  text-align: center;
}

@media screen and (max-width: 600px) {
  .warning-msg {
    font-size: 16px !important;
    width: 90%;
  }
}

.document {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 90vw;
  text-align: start;
}

.document p {
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.5;
  width: 100%;
}

.document ol {
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.5;
  list-style-type: decimal;
  margin: 0;
  padding: 0;
  margin-left: 50px;
}

.document ol li {
  list-style-type: decimal;
  padding: 0;
}

.document ul {
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.5;
  list-style-type: disc;
  margin: 0;
  padding: 0;
  margin-left: 50px;
}

.document ul li {
  list-style-type: disc;
  padding: 0;
}

.document h1 {
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
}

.document h2 {
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 10px;
  text-align: start;
  width: 100%;
}

.document h2,
.document h4,
.document h5,
.document h6,
.document h3 {
  width: 100%;
}
