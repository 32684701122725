@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.for {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
}
.captchaStyle{
  margin-top: 15px;
  border-radius: 12px;
  margin-left: 10px;
}

/* .parent {
  background-color: #f7d30bf1;
  width: 17rem;
  text-align: left;
  padding: 1rem 1.4rem 1rem 1.2rem;
  border-radius: 1rem;
  box-shadow: -3px -2px 13px -8px rgba(0, 0, 0, 0.46);
} */
/* .parent h2 {
  color: rgb(85, 84, 84);
  font-weight: 500;
} */

/* .contact-info {
  padding-top: ;
} */

.contact {
  display: flex;
  text-align: left;
  align-items: center;
  margin: 1.85rem 0;
}
.contact p {
  color: rgba(175, 45, 45, 0.966);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.9rem;
  text-transform: capitalize;
  font-family: "Roboto", sans;
}
.social i {
  padding: 1rem 1rem 2rem 1rem;
}

.contact i {
  padding-right: 1rem;
}

.for {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
}
.for input {
  margin: 1rem 0 0 0;
  padding: 0.6rem;
  border-radius: 2rem;
  border: 1px solid #fed900;
  background-color: rgb(240, 238, 238);
}
.for textarea {
  margin: 1rem 0 0 0;
  border-radius: 0.8rem;
  border: 1px solid #fed900;
  background-color: rgb(240, 238, 238);
  resize: none;
}

.touch {
  background-color: white;
  border-radius: 1rem;
  width: 50%;
  /* text-align: left; */
  padding: 0 5rem 0 5rem;
  margin: auto;
  justify-content: center;
  box-shadow: -3px -2px 23px -2px rgba(0, 0, 0, 0.33);
}
.touch h2 {
  letter-spacing: 0.1rem;
}
form {
  padding: 1.5rem 0rem 2rem 0;
}
form h2 {
  color: #fed900;
  font-weight: 500;
}
form p {
  padding-top: 0.5rem;
  color: rgb(156, 154, 154);
}
.grand_parent {
  position: relative;
  height: auto;
  padding: 3rem 0;
  display: flex;
  padding-top: 7%;
  align-items: center;
  background-color: #f9efb7;
  /* margin: 0 auto; */
}
.parent {
  position: absolute;
  left: 16rem;
}
.switchh {
  display: flex;
}
.control {
  text-align: center;
  color: rgb(102, 101, 101);
  font-family: "Roboto", sans-serif;
  padding: 0.5rem 2rem 0.5rem 2rem;
  border-radius: 2rem;
  background-color: #fed900;
  border: 1px solid #fed900;
  font-weight: 500;
  letter-spacing: 1px;
  cursor: pointer;
  height: 3rem;
  margin: auto;
  /* color: white; */
  box-shadow: -3px -2px 28px -8px rgba(0, 0, 0, 0.46);
}
.control:hover {
  transform: scale(0.9);
  cursor: pointer !important;
}
::placeholder {
  color: rgb(158, 152, 152);
  font-size: 0.85rem;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
textarea {
  padding: 0.5rem;
}
