.home_support_request {
  display: flex;
  flex-direction: column;
  justify-content: fl ex-start;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  background-color: transparent;
}
.home_support_request h2 {
  color: #f58320;
  font-size: 2rem;
  text-align: center;
}

.home_support_request p {
  font-size: 1.2rem;
  text-align: justify !important;
  width: 90vw !important;
}

.home_support_request p:nth-child(3) {
  font-size: 1.2rem;
  align-self: unset;
  text-align: center !important;
}
