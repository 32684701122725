@import url("https://fonts.googleapis.com/css2?family=Artifika&family=Montserrat:wght@100;400&family=Roboto:wght@100;300&family=Taviraj:wght@300&family=Ubuntu:wght@300&display=swap");

#usedralaPage {
  width: 100%;
  height: auto;
  margin-top: 20vh;
}

#usedralaPage #sectionContainer {
  height: auto;
  display: flex;
}

#usedralaPage #sectionContainer .dralaPayBox {
  width: 100vw;
  height: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

#usedralaPage #sectionContainer .dralaPayBox h3 {
  /* border: 1px solid black; */
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  color: #fa7200;
}

#usedralaPage #sectionContainer .dralaPayBox .whiteBox {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

#usedralaPage #sectionContainer .dralaPayBox .whiteBox .boxOne {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}

#usedralaPage #sectionContainer .dralaPayBox .whiteBox .boxOne .pay {
  align-items: center;
}

#usedralaPage #sectionContainer .dralaPayBox .whiteBox .boxOne .pay img {
  width: 10vw;
  height: auto;
}

#usedralaPage #sectionContainer .dralaPayBox .whiteBox .boxOne p {
  font-family: Roboto;
  font-style: bold;
  font-size: 1.3rem;
  color: #000000;
  text-align: left;
  width: 15vw;
}

#usedralaPage #sectionContainer .dralaPayBox .whiteBox .boxOne button {
  font-family: Taviraj;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  color: #ffffff;
  background: #f5831f;
  border: none;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  padding: 12px 24px;
}

#usedralaPage #sectionContainer .dralaPayBox .whiteBox .boxOne button:hover {
  transform: scale(1.1);
}

#usedralaPage #sectionContainer .dralaPayBox .whiteBox .boxTwo {
  width: 20vw;
  height: 100%;
}

#usedralaPage #sectionContainer .dralaPayBox .whiteBox .boxTwo img {
  width: 100%;
  height: 100%;
}

#usedralaPage .listsBox {
  height: 288px;
}

#usedralaPage .listsBox .head h2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 2.25rem;
  margin-top: 43px;
}

#usedralaPage .listsBox .clickHere {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 2.25rem;
  margin-top: 56px;
  cursor: pointer;
}

#usedralaPage .listsBox .clickHere a {
  display: inline-block;
  width: auto;
  position: relative;
  overflow: hidden;
}

#usedralaPage .listsBox .clickHere a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #0075ff;
  bottom: 0px;
  border-radius: 10px;
  left: -100%;
  transition: all 0.3s ease-in-out;
}

#usedralaPage .listsBox .clickHere a:hover::before {
  left: 0;
}

.clickHere a {
  color: #0075ff;
}

#usedralaPage .listsBox .para p {
  text-align: center;
  color: #f5831f !important;
}

.map {
  background-color: rgb(214, 210, 210);
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 1rem;
  padding: 1rem;
}
iframe {
  cursor: default;
  width: 100%;
  margin-bottom: 2rem;
}

.location {
  background: #f9efb7;
  font-size: 1.5rem;
}

.location h3 {
  font-weight: 600;
  color: #fa7200;
  text-transform: capitalize;
  padding: 1rem;
}

.paraa {
  font-size: 1.6rem;
  font-weight: bolder;
  margin-top: 2rem;
}

.boxe {
  text-align: left;
  background-color: white;
  padding: 1rem;
  width: 22rem;
  margin-bottom: 1rem;
}

.search {
  display: flex;
  justify-content: space-between;
  padding: 0rem 0 1rem 0;
}
.search input {
  width: 17rem;
  height: 2rem;
  border: #fa7200 solid 1px;
}

.search button {
  background-color: #fa7200;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
}

.boxe h4 {
  font-weight: 500;
  text-transform: capitalize;
  padding: 0 0 0.5rem 0;
}
.boxe p {
  color: gray;
  font-size: 0.8rem;
  padding: 0.1rem;
}
.boxe .pa {
  color: #f5831f;
}

.butt button {
  background-color: #f5831f;
  color: white;
  border: none;
  margin: 0 0.7rem 0 0;
  padding: 0.2rem 0.4rem;
  text-transform: capitalize;
  font-size: 0.7rem;
}
.boxes {
  /* background-color: #f5831f; */
  height: 27rem;
  overflow-y: scroll;
}

@media screen and (max-width: 600px) {
  #usedralaPage #sectionContainer .dralaPayBox {
    display: flex;
    flex-direction: column;
  }

  #usedralaPage #sectionContainer .dralaPayBox h3 {
    font-size: 24px !important;
  }

  .whiteBox {
    flex-direction: column;
    align-items: center;
  }

  .text p {
    font-size: 14px !important;
  }

  #usedralaPage #sectionContainer .dralaPayBox .whiteBox .boxOne .pay img {
    width: 30vw;
    height: auto;
  }

  #usedralaPage #sectionContainer .dralaPayBox .whiteBox .boxOne p {
    width: 40vw;
  }

  #usedralaPage #sectionContainer .dralaPayBox .whiteBox .boxTwo {
    width: 50vw;
  }

  #usedralaPage .listsBox .head h2 {
    font-size: 16px;
  }

  #usedralaPage .listsBox .clickHere {
    font-size: 16px;
  }
}
