.body {
  background-image: url(https://s3-alpha-sig.figma.com/img/2b57/b7a3/b4bedcba00035ee8b24641c7b46542cd?Expires=1646611200&Signature=f2dgdmId2re6328Ox2yr0hulYRozk2R7INbB4EIHQL-8fJwCzorP8ZL4ejCpVjHkuOuX9h-ie0zVqp5Y-DNjmPx~sv3Xi30B6lYfwPXLT-poft1vFIojIXdAyE37IYCZQGDojIMo41-4kwbfmxOwYiqELAMCyd~922Ik5KYv-mu1nUUntF7KnC1Y1OTRqZpGivoIBdIgR95r9AR9GEE83jyyH4Cf8EX-yZl8Q8Fm4mAf7EBqku4Fb~iQSZe00US1xhtVKsl9YgoIv8CQ7XZMWNcZZ6LEb3H8mTVwxiNayBEUFlnQBIFi86y0YrGMP0OyuepJKz4J0NhW~uFNn1znEg__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
}

.trans {
  background-color: rgba(235, 233, 233, 0.671);
  width: 80%;
  height: 90%;
  /* display: grid; */
  /* place-items: center; */
  border-radius: 5px;
  backdrop-filter: blur(4px);
}

.ed {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: capitalize;
  font-size: 0.8rem;
  padding: 1rem;
}
.ed i {
  padding-right: 1rem;
  font-size: 1.2rem;
}

.ed i:hover {
  color: #f58320;
}

.head h2 {
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  color: #454545;
}
.input {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 80%;
  align-self: center;
  padding-top: 2rem;
}

.input label {
  font-family: "Ubuntu", sans-serif;
  color: #454545;
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 20px;
  text-transform: capitalize;
}
.input label:not(:last-child) {
  margin-bottom: 1rem;
}

.input input {
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1.2rem;
  width: 100%;
}

.input .inputGrp {
  padding: 5px 10px;
  border-bottom: 1px solid #454545;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tick {
  font-size: 0.9rem;
  font-weight: 400;
  color: #f58320;
  margin: 0 auto;
  padding: 1rem;
  /* text-transform: capitalize; */
}

.trans .button {
  border: 1px solid transparent;
  background-color: #f58320;
  color: aliceblue;
  font-weight: 400;
  text-transform: capitalize;
  padding: 0.5rem 1.5rem;
  font-size: 1.1rem;
  letter-spacing: 0.3px;
  border-radius: 5px;
  margin: 0 auto;
}
.input a {
  margin: 1.5rem auto 0 auto;
}
.trans .button:hover {
  background-color: white;
  color: #f58320;
  border: 1px solid #f58320;
}
.lock i {
  /* font-size: 3rem;
  color: #f58320;
  border: 2px solid #f58320;
  padding: 1.4rem 1.6rem 1.6rem 1.4rem;
  text-align: center;
  align-items: center;
  border-radius: 50%; */

  font-size: 3rem;
  height: 3rem;
  width: 3rem;
  border: 3px solid #f58320;
  padding: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  border-radius: 50%;
  color: #f58320;
}
/* show hide */

.message {
  visibility: hidden;
}

@media screen and (max-width: 600px) {
  .input label {
    font-size: 14px;
  }
  .input input {
    font-size: 16px;
  }
}
