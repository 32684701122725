.assetContainer {
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 40px;
}

.assetContainer .image img {
  max-width: 100%;
}
.loadingText{
  color: #fed900;
  font-size: 2rem;
  font-weight: 500;
  font-family: "Roboto", sans-serif;

}
.counter {
  font-weight: bold;
  font-size: 2.5rem;
  color: #f5831f;
  width: 80vw;
  align-self: center;
  word-wrap: break-word;
}

.count {
  font-weight: 700;
  font-size: 0.75rem;
  color: #f5831f;
}

.head1 {
  width: 80vw;
  align-self: center;
}

.head1 h2 {
  font-weight: bolder;
  font-size: 2rem;
}
.texContainer {
  font-weight: normal;
  font-size: 1.5rem;
  text-align: left;
  width: 80vw;
  align-self: center;
  line-height: 130%;
}

.texContainer ol {
  padding: 20px !important;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.texContainer li {
  list-style: decimal !important;
  padding: 0 !important;
}

.head2 h2 {
  font-weight: bolder;
  font-size: 2rem;
}

.files {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 80vw;
  align-self: center;
}

.files h3 {
  font-weight: 500;
  font-size: 2rem;
}

.files .downloadFiles img {
  height: 48px;border:1px solid #fed900;border-radius: 12px;padding:5px
}

.downloadFiles {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  width: 80vw;
  align-self: center;
}

.files .downloadFiles .imgbox p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
}
.imgboxAssets{
  border:1px solid #fed900;
}
.imgboxAssets img{
  height:48
  
}

.dralaUnited {
  margin-bottom: 40px;
}

.dralaUnited img {
  width: 186px;
  height: 186px;
}

@media screen and (max-width: 600px) {
  .counter {
    font-size: 24px;
  }

  .head1 h2,
  .head2 h2 {
    font-size: 24px;
  }

  .files h3 {
    font-size: 24px;
  }

  .texContainer {
    font-size: 16px;
  }
}
