.business_list__container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #f9efb7;
  padding: 20px 10vw;
  flex: 1;
}

.business_list__wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.business_list__item {
  display: flex;
  flex-direction: column;
  gap: 7px;
  align-items: flex-start;
  justify-content: flex-start;
}

.business_list__item__content {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  gap: 4px;
}

.business_list__item__image__wrapper {
  display: flex;
  gap: 20px;
}

.business_list__item__image__wrapper img {
  width: 120px;
}

.business_list__item__content__name {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: start;
}

.business_list__item__content__link,
.business_list__item__content__facebook {
  width: max-content;
  font-weight: normal;
}
.business_list__item__content__link:hover,
.business_list__item__content__facebook:hover {
  text-decoration: underline;
}

.business_list__item__content__telephone {
  width: max-content;
}

.business_list__item__content__description {
  font-size: 1rem;
  text-align: start;
  line-height: 1.5;
}

.description__text {
  padding: 20px;
  font-size: 18px;
  max-width: 60vw;
  min-width: 50vw;
  margin: auto;
  text-align: justify;
  line-height: 1.5;
}
