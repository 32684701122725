.communities__list {
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 90vw;
}

.communities__list__item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: justify;
}

.communities__list__item__title {
    font-size: 1.2rem;
}

.communities__list__item__title:hover {
    text-decoration: underline;
}

.communities__list__item__title-small {
    font-size: 1rem !important;
}

.communities__list__item__description {
    font-size: 1rem !important;
    line-height: 1.5;
}