#containerFaq {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding: 20px 0;
}

#containerFaq h1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bolder;
  font-size: 2rem;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.textContainerFaq {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  text-align: left;
  line-height: 150%;
}

.textContainerFaq h4 {
  font-weight: bold;
  font-size: 1.2rem;
  margin-top: 15px;
  margin-bottom: 15px;
}

.textContainerFaq h4:not(:first-child) {
  margin-top: 15px;
}

.document {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 90vw;
  text-align: start;
  margin-bottom: 40px;
}

.document p {
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.5;
  width: 100%;
}

.document ol {
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.5;
  list-style-type: decimal;
  margin: 0;
  padding: 0;
  margin-left: 50px;
}

.document ol li {
  list-style-type: decimal;
  padding: 0;
}

.document ul {
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.5;
  list-style-type: disc;
  margin: 0;
  padding: 0;
  margin-left: 50px;
}

.document ul li {
  list-style-type: disc;
  padding: 0;
}

.document h1 {
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
}

.document h2 {
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 10px;
  text-align: start;
  width: 100%;
}

.document h4 {
  font-weight: bold;
  font-size: 1.25rem;
  margin: 10px 0;
  margin-top: 20px;
  text-align: start;
  width: 100%;
}
