@import url("https://fonts.googleapis.com/css2?family=Artifika&family=Montserrat:wght@100&family=Roboto:wght@100;300&family=Ubuntu:wght@300&display=swap");

#container {
  color: black;
  width: 100%; /*or 100vw*/
  /* width: 100%; */
  /* height: 570rem; */
  background: #ffffff;

  margin-top: 20vh;
}

.logo img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
  /* padding-top: 15%; */
}

.textContainer {
  /* position: absolute; */

  font-family: "Roboto";
  font-style: normal;
  font-size: 20px;

  color: #000000;
  text-align: justify;
  padding: 0px 100px;
}

/* .textContainer .photo {
  display: flex;
  margin: auto;
  width: 800px;
  height: 500px;
  margin-top: 40px;
} */

.textContainer p {
  margin: 20px auto;
  line-height: 150%;
}

.textContainer ul {
  margin: 10px auto;
}
.textContainer ul li {
  margin: 10px auto;
}
.textContainer ul li ul li {
  margin: 10px auto;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding: 20px 0;
}
