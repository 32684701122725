.communitiesContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
}

.image1 img {
  width: 227px;
  height: 177.5px;
  margin-top: 100px;
}

.textC {
  text-align: justify;
  width: 80vw;
  align-self: center;
}
.textC p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 130%;
}

.leftText {
  text-align: center;
  width: 60vw;
  align-self: center;

  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}
.leftText p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
}

.accepting_comm {
  text-align: start;
  margin-top: 20px;
}

.accepting_comm li {
  list-style-type: decimal-leading-zero;
  padding: 0;
}

.accepting_comm li a {
  font-size: 1.2rem !important;
}

.image2 {
  width: 30vw;
  align-self: center;
}
.image2 img {
  max-width: 100%;
  margin-top: 20px;
}
.mainPosts {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: stretch;
  justify-items: stretch;
  align-items: stretch;
  margin-top: 20px;
  gap: 30px;
  padding: 20px;
}
