.docs-section {
  max-width: 80vw;
  display: block;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 50px;
}
.docs-section h1 {
  font-size: 32px;
  margin-bottom: 10px;
}
.docs-section h2 {
  font-size: 28px;
  margin-bottom: 10px;
}
.docs-section h4 {
  font-size: 18px;
  text-align: start;
  margin-bottom: 10px;
}
.docs-section p {
  text-align: justify;
  line-height: 150%;
  margin-bottom: 10px;
}
.docs-section ul {
  padding: 0 0 0 40px;
  margin-bottom: 10px;
}
.docs-section ul li {
  list-style: disc !important;
  padding: 0 !important;
}

#containerPolicy {
  margin: auto;
  margin-top: 40px;
  width: 80vw;
  display: block;
  line-height: 150%;
  margin-bottom: 50px;
}

.textContainerPolicy {
  margin-top: 20px;
}

.textContainerPolicy h4 {
  font-size: 18px;
  margin-top: 40px;
}

.textContainerPolicy p {
  text-align: justify;
  line-height: 150%;
  margin-bottom: 10px;
}
