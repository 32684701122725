#exchangeContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 20px;
}

.image1 {
  width: 80vw;
  align-self: center;
}
.image2 {
  width: 80vw;
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.imageDiv {
  width: 40vw;
}

.image1 img {
  width: 227px;
  height: 177.5px;
}

.image2 img {
  max-width: 100%;
}

.image2 p {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
}

.textCont {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  text-align: justify;
  width: 80vw;
  line-height: 150%;
  align-self: center;
  margin-bottom: 30px;
}

.head h2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
}

.image3 {
  width: 50vw;
  align-self: center;
}

.image3 img {
  max-width: 100%;
}

@media screen and (max-width: 600px) {
  .image2 p {
    font-size: 24px;
  }

  .textCont {
    font-size: 18px;
  }
}

.comparison_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.comparison_card {
  width: 200px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.comparison_card:hover {
}

.comparison_card p {
  font-size: 2rem;
}

.comparison_card p span {
  font-size: 1rem;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.comparison_list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  min-width: 50vw;
  gap: 40px;
}
