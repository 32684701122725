* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.main {
  display: grid;
  grid-template-columns: 1fr 3fr;
  height: 100vh;
  font-family: "Roboto", sans-serif;
  margin-top: 8vh;
}
.sidemenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-transform: capitalize;
  background: #f9efb7;
  border: solid 1px orange;
}
.sidemenu li {
  padding: 0.5rem;
  color: black;
  font-weight: 600;
  font-size: 1rem;
  border-bottom: 1px solid orange;
  width: 100%;
  text-align: left;
  padding: 0.5rem 0 0.5rem 5rem;
  cursor: pointer !important;
}
.sidemenu a {
  color: black;
  font-weight: 600;
  font-size: 1rem;
}
.sidemenu li:hover {
  background: linear-gradient(90deg, rgba(245, 131, 31, 0) 9.9%, #f5831f 100%);
}

/* .sidemenu a:hover {
  background: #f5831f;
} */
.sidemenu span {
  font-size: 1rem;
  color: red;
  display: inline-block;
  align-items: center;
}

.app_down h3 {
  color: orange;
  text-transform: capitalize;
  padding: 1rem;
  font-size: 1rem;
}
.mainn {
  background-color: #f9efb7;
  font-family: "Roboto", sans-serif;
}
.app_down img {
  width: 40%;
}
.mother {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.mother .fa-qrcode {
  font-size: 3rem;
  /* font-family: "Roboto", sans-serif; */
}
.adress {
  padding: 3rem 5rem;
  text-align: left;
}
.adress h4 {
  font-size: 1rem;
  text-transform: capitalize;
}
.adress p {
  color: gray;
}
.copy i {
  font-size: 1.2rem;
  font-weight: normal;
  color: gray;
}
.copy p {
  text-transform: capitalize;
}
.total {
  text-align: left;
  margin: 2rem;
}
.total h2 {
  text-transform: capitalize;
  border-bottom: 1px solid orange;
  font-size: 1.3rem;
}
.ammount {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  font-weight: 200;
  border-bottom: 1px solid orange;
}
.rupee {
  text-align: center;
}
.rupee p {
  font-weight: bold;
}
.amt {
  color: gray;
  font-weight: normal !important;
}
