#footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
  /* width: 100vw; */
  /* height: 30vh; */
  gap: 10vw;
  color: rgb(212, 211, 211);
  background-color: black;
  /* position: sticky;
  bottom: 0; */
  margin-top: auto;
}
.contactUsSec a {
  color: #fed900;
  font-size: 2rem;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}
.contactUsSec a:hover {
  color: rgb(212, 211, 211);
}

.box2 {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  height: 100%;
  /* background-color: red; */
  place-items: center;
}
.boxes3 i {
  padding: 1rem;
  font-size: 2.1rem;
  color: white;
}

.boxes3 i:hover {
  color: #fed900;
}
.terms h3 {
  text-transform: capitalize;
  padding: 0.2rem;
  font-size: 0.9rem;
  font-family: "Roboto", sans-serif;
}
.copy h3 {
  text-transform: capitalize;
  padding: 0.3rem;
  font-size: 0.9rem;
  font-family: "Roboto", sans-serif;
}

.imageSec {
  justify-self: end;
  display: flex;
  align-items: center;
  justify-content: end;
}

.imageSec img {
  width: 38vh;
}

.download_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  width: 100%;
  height: 100%;
}

.download_section_links {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}
.download_section_links a img {
  width: 50%;
  height: auto;
}
.download_section_links a:hover img {
  transform: scale(1.1);
}
@media screen and (max-width: 900px) {
  #footer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* width: 100%; */
    /* height: 30rem; */
    place-items: center;
    background-color: black;
    padding: 20px;
  }
  .box2 {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    height: 100%;
    /* background-color: red; */
    place-items: center;
  }
  .terms h3 {
    font-size: 0.8rem;
  }
  .copy h3 {
    font-size: 0.8rem;
  }
  .boxes3 i {
    padding: 0.7rem;
    font-size: 1.7rem;
  }
  .boxx3 img {
    width: 100%;
  }
  .box11 a {
    font-size: 1.7rem;
  }
}
