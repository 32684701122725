.propositionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding: 20px 0;
}

.propositionContainer h1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 20px;
}

.tex1Container {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  text-align: left;
  padding: 1px 118px;
  padding-top: 5%;
  letter-spacing: 4%;
  line-height: 160%;
}

.tex1Container .barcodeContainer {
  display: flex;
  justify-content: right;
  padding-top: 30px;
}

.tex1Container .barcodeContainer .text {
  width: 377px;
  display: flex;
  align-items: center;
  text-align: center;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 1.188rem;
  margin-right: 30.5px;
}

.tex1Container .barcodeContainer img {
  width: 20vw;
}

.tex2Container div p {
  line-height: 1.5;
}

.title {
  font-weight: bold;
  font-size: 1.4rem;
  margin-bottom: 10px;
  margin-top: 40px;
}

.tex2Container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.content {
  width: 90vw;
  text-align: start;
}

.description {
  line-height: 1.5;
}

.document {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 90vw;
  text-align: start;
}

.document p {
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.5;
  width: 100%;
}

.document ol {
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.5;
  list-style-type: decimal;
  margin: 0;
  padding: 0;
  margin-left: 50px;
}

.document ol li {
  list-style-type: decimal;
  padding: 0;
}

.document ul {
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.5;
  list-style-type: disc;
  margin: 0;
  padding: 0;
  margin-left: 50px;
}

.document ul li {
  list-style-type: disc;
  padding: 0;
}

.document h1 {
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
}

.document h2 {
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 10px;
  text-align: start;
  width: 100%;
}
