@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap"); /* Montserrat */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100&family=Ubuntu:wght@300&display=swap");
/*Ubuntu*/
* {
  transition: all 0.3s ease-in-out;
}
#navbar {
  position: sticky;
  top: 0;
  /* width: 100vw; */
  z-index: 1;
  backdrop-filter: blur(20px);
}

.margin_top {
  display: flex;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.8);
  align-items: center;
  padding: 20px;
}

li {
  list-style: none;
  padding: 0 1rem;
}
#navbar .margin_top .right li a {
  text-decoration: none;
  color: white;
  cursor: pointer;
  font-size: 0.9rem !important;
  letter-spacing: 0.1rem;
}
#navbar .margin_top .right li a:hover {
  border-radius: 6px;
  color: #fed900;
}
.right li:hover {
  transform: scale(1.2);
}

.right {
  display: flex;
}

.logo1 img {
  width: 5.5rem;
}

.languagebar select {
  color: white;
  background-color: black;
  cursor: pointer;
  border: 1px solid #ffffff;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.8);
  width: 130px;
  height: 29px;
  padding: 0px 10px;
  margin-top: -3px;
}

.logo1 {
  display: flex;
  justify-content: space-between;
  /* width: 100vw; */
}

.side_bar {
  font-size: 1rem;
  background: transparent;
  border: transparent;
  display: none;
  color: #fed900;
}

@media screen and (max-width: 950px) {
  .logo1 {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .side_bar {
    font-size: 1.5rem;
    border: transparent;
    display: block;
    z-index: 100;
  }

  #bar {
    height: 0vh;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }
  .right {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    align-items: center;
    transition: all 0.3s ease-in-out;
    background-color: rgba(0, 0, 0, 0.493);
    width: 100vw;
    height: 100vh;
  }
  #bar.active {
    /* height: 70vh; */
    transition: all 0.3s ease-in-out;
  }

  .right li {
    padding: 10px 0;
    transition: all 0.3s ease-in-out;
    width: 100vw;
  }
  .right li:hover {
    transform: scale(1.2);
    background: #fed900;
    color: white;
  }
  #navbar .margin_top .right li a:hover {
    border-radius: 6px;
    color: white;
  }
}
