@import url("https://fonts.googleapis.com/css2?family=Artifika&family=Montserrat:wght@100&family=Roboto:wght@100;300&family=Ubuntu:wght@300&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Artifika&family=Montserrat:wght@100;400&family=Roboto:wght@100;300&family=Ubuntu:wght@300&display=swap");

#newContainer {
  /* height: 755px; */
  background-color: #f0f0f0;
  display: grid;
  place-items: center;
}

#newContainer .head {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 20px;
  color: #f58320;
  padding-top: 50px;
}

#newContainer .boxxContainer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px;
}

#newContainer .boxxContainer .box1 {
  max-width: 20vw;
  min-width: 280px;
  background-color: white;
  box-shadow: -3px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 4px;
}

.boxess1 {
  width: 370px;
  height: 180px;
}

#newContainer .boxxContainer .box1 .boxess1 {
  width: 100%;
  background-color: #f58320;
  box-shadow: 0px 4px 4px rgba(48, 47, 47, 0.25);
}

#newContainer .boxxContainer .box1 .boxess1 img {
  width: 100%;
  height: 100%;
}

#newContainer .boxxContainer .boxess2 {
  padding: 30px;
}

#newContainer .boxxContainer .box2 {
  box-shadow: -3px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 4px;
}

#newContainer .boxxContainer .box2 .boxess1 img {
  width: 100%;
  height: 100%;
}

#newContainer .boxxContainer .box3 {
  box-shadow: -3px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 4px;
}

#newContainer .boxxContainer .box3 .boxess1 img {
  margin-top: 35px;
  width: 340px;
  height: 127px;
}

#newContainer .boxxContainer .box3 .boxess1 {
  background-color: #ffffff;
}

#newContainer .boxxContainer .boxess2 {
  background-color: #ffffff;
}

.boxess2 .text {
  padding: 19px 12px;
  text-align: left;
  font-family: Montserrat;
  font-weight: bold;
  line-height: 30px;
  font-size: 1.2rem;
  color: #111111;
}

.boxess2 .text p {
  font-family: Roboto;
  font-size: 1rem;
  font-weight: normal;
  line-height: 25px;
}

span a {
  text-decoration: none;
  color: blue;
}

@media screen and (max-width: 950px) {
  .box3 {
    display: none;
  }
}
