.lyt_importWallet_wrapper {
  display: flex;
  flex-direction: column;
  /* width: 100vw; */
  height: max-content;
}

.lyt_importWallet_wrapper .importWallet_wrapper_div {
  margin-top: 8vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: grid;
  place-items: center;
}

.lyt_importWallet_wrapper .importWallet_wrapper_div > div {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(30px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 10px;
  margin: 20px;
  margin-top: 40px;
  width: 70vw;
  padding: 20px;
}

.lyt_importWallet_wrapper .importWallet_wrapper_div .wrapper_div_backButton {
  background: none;
  border: none;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 3.2vh;
  cursor: pointer;
}

.lyt_importWallet_wrapper .codeVerificationForm {
  display: grid;
  place-items: center;
}

.lyt_importWallet_wrapper .codeVerificationForm .codeVerifySection {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.lyt_importWallet_wrapper .codeVerificationForm .codeVerifySection label {
  width: 100%;
  text-align: start;
  color: #2b2b2b;
  font-family: "Open Sans", sans-serif;
}

.lyt_importWallet_wrapper .codeVerificationForm .codeVerifySection input {
  outline: none;
  border: none;
  background: none;
  border-bottom: 1px solid #000;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  width: 100%;
  padding: 0.5vh 0;
}

.lyt_importWallet_wrapper
  .codeVerificationForm
  .codeVerifySection
  .submitButton {
  background: #f58320;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  align-self: center;
  margin-top: 40px;
  font-size: 1rem;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 12px 24px;
  border-radius: 5px;
}

.lyt_importWallet_wrapper
  .codeVerificationForm
  .codeVerifySection
  .submitButton:hover {
  color: #f58320;
  border: 1px solid #f58320;
  background: none;
}

@media screen and (max-width: 600px) {
}
