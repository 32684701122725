.banners {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}

.chat {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0.5rem 0px;
  margin: 0 5px;
  text-transform: capitalize;
}

.chat h1 {
  font-weight: 500;
}

.chat1 img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin: auto 0;
}
.chat1 {
  display: flex;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 1rem 0.4rem;
  padding: 0.5rem 1rem;
  background-color: rgba(240, 240, 240, 0.534);
}

.infos {
  text-align: left;
  padding-left: 1rem;
}

.infos h3 {
  font-size: 1.2rem;
  letter-spacing: 1px;
}

.infos h4 {
  font-weight: 500;
}
.infos p {
  color: gray;
  font-size: 0.8rem;
}
a {
  color: black;
  font-weight: 500;
}
