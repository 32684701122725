@import url("https://fonts.googleapis.com/css2?family=Artifika&family=Montserrat:wght@100&family=Roboto:wght@100;300&family=Ubuntu:wght@300&display=swap"); /*Roboto*/

@import url("https://fonts.googleapis.com/css2?family=Artifika&family=Montserrat:wght@100&family=Roboto:wght@100;300&family=Ubuntu:wght@300&display=swap"); /*Roboto*/

#context {
  background-color: white;
  font-family: "Roboto", sans-serif;
  display: grid;
  place-items: center;
  padding: 20px;
  padding-bottom: 20px;
}

.textContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  /* gap: 10px; */
  font-family: Roboto;
  font-style: normal;
  text-align: left;
  margin-top: 20px;
}

.textContainer .text1Container {
  width: calc(50vw - 40px);
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
}

.textContainer .text1Container .textHeading {
  font-weight: bold;
  font-size: 2rem;
}

.textContainer .text1Container .textHeading p {
  font-weight: normal;
  font-size: 1rem;
}

.textContainer .text2Container {
  width: calc(50vw - 40px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
  gap: 10px;
}

.textContainer .text2Container .textHeading {
  font-weight: bold;
  font-size: 1.3rem;
}

.textContainer .text2Container .textHeading p {
  font-weight: normal;
  font-size: 1rem;
  margin-top: 23px;
}

.textContainer .text2Container .text {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1rem;
}

/* .textContainer2 */

.textContainer2 {
  /* width: 50vw; */
  /* margin: -37px 110px; */
  display: flex;
  text-align: left;
  justify-content: space-between;
  font-family: Roboto;
  padding: 20px;
  font-style: normal;
}

.textContainer2 .text1Container {
  width: calc(50vw - 40px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
  gap: 10px;
}

.textContainer2 .text2Container {
  width: calc(50vw - 40px);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
  gap: 10px;
}

.textContainer2 .textHeading {
  font-weight: bold;
  /* font-size: 2rem; */
}

.textContainer2 .text {
  margin-top: 10px;
}

.readmore a {
  text-decoration: none;
  color: blue;
}

.text2Container .readmore a {
  color: blue;
}

.textHeading a {
  text-decoration: none;
  color: #f58320;
  font-size: 2rem;
}

#context .videoContainer {
  background-color: white;
  margin-top: 20px;
}

#context .videoContainer .videoText {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;

  color: #f58320;
}

#context .videoContainer .videoBox {
  padding-top: 56.25%;
  position: relative;
  margin-top: 20px;
}

#context .videoContainer .videoBox .reactPlayer {
  position: absolute;
  top: 0;
  left: 0;
}

#context .videoContainer .videoBox .whiteBox img {
  width: 100px;
  height: 105px;
}

@media screen and (max-width: 900px) {
  .textContainer {
    flex-direction: column;
    gap: 20px;
  }
  .textContainer2 {
    margin-top: 20px;
    flex-direction: column;
    gap: 20px;
  }

  .textContainer .text2Container .text {
    font-size: 16px;
    width: 100%;
  }

  .textContainer .text1Container {
    width: 100%;
  }

  .textContainer .text2Container {
    width: 100%;
  }

  .textContainer2 .text1Container {
    width: 100%;
  }

  .textContainer2 .text1Container {
    width: 100%;
  }

  .textContainer2 .text2Container {
    width: 100%;
  }
}
