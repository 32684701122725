.posts {
  flex: 9;
  /* background-color: #f0f0f0; */
  /* height: auto; */
}

.posts .mainPosts {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: stretch;
  justify-items: stretch;
  align-items: stretch;
  margin-top: 20px;
  gap: 20px;
  padding: 20px;
}

.link {
  text-decoration: none;
  color: black;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: bold;
  margin-left: auto;
  margin-right: 20px;
}

.link:hover {
  color: orange;
}

.post {
  max-width: 300px;
  height: 100%;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Roboto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.post .b1 {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 207px;
  background-color: #f58320;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 20px 0px;
}

.post .b1 img {
  width: 200px;
  height: 100%;
  object-fit: cover;
}

.post .postTitle {
  font-size: 1.4rem;
}

.post .postDate {
  font-style: normal;
  font-weight: normal;
  /* font-size: 17px; */
  /* line-height: 23px; */
}

.postImageDiv {
  align-self: center;
  width: 20vw;
  height: 200px;
  overflow-y: hidden;
  display: grid;
  place-items: center;
}

.post .postImg {
  width: 10vw;
  height: auto;
}
a {
  text-decoration: none;
  color: blue;
}

@media screen and (max-width: 600px) {
  .post {
    max-width: 100% !important;
  }
  .postImageDiv {
    width: 100% !important;
  }
  .postImageDiv .postImg {
    width: 100%;
  }
}
