@import url("https://fonts.googleapis.com/css2?family=Artifika&family=Montserrat:wght@100&family=Ubuntu:wght@300&display=swap"); /*Artifica*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400&family=Ubuntu:wght@500;700&display=swap");

#sectionContainer {
  width: 100%;
  z-index: 0;
  background-color: #f9efb7;
  padding: 0 8vw;
}

#mainLogo {
  padding-top: 10vh;
  margin-bottom: 40px;
  width: 40vw;
}
#mainLogo img {
  width: 100%;
  height: auto;
}

#sectionContainer #videosContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
  gap: 20px;
  margin-top: 10vh;
}

#sectionContainer #videosContainer .videoCards .name {
  font-family: Ubuntu;
  font-size: 1.4rem;
  line-height: 20px;
  padding: 0 0 1.8rem 0;
  font-weight: 500;
}

#sectionContainer #videosContainer .videoCards {
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
}
#sectionContainer #videosContainer .videoCards .videoBox {
  position: relative;
  padding-top: 56.25%;
}

#sectionContainer #videosContainer .videoCards .videoBox .reactPlayer {
  position: absolute;
  top: 0;
  left: 0;
}

#sectionContainer .textContainer {
  font-weight: normal;
  font-size: 1.8rem;
  text-align: left;
}
@media screen and (max-width: 950px) {
  #sectionContainer #videosContainer {
    grid-template-columns: 1fr;
  }

  #sectionContainer #mainLogo {
    width: 80%;
  }

  #sectionContainer .textContainer {
    font-size: 16px;
    line-height: 150%;
    text-align: justify;
  }
}
