@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&family=Ubuntu:wght@300;400;500&display=swap");

.body {
  background-image: url(https://s3-alpha-sig.figma.com/img/cd4b/74e5/695123c134d3a165e4cc6f59bc53a76c?Expires=1646611200&Signature=f~QQz1bc-dRhl6lCOYt1gt8DhhOv1uDbkLMYMO-2PyfisbwZjZCyC23zZ02LgRBjTKVEZjiszqadA5j3kG6lJpgewOim5N5qVLFX-RSulTQCQZPuKSfNChHoSaAPTHWL9QvLS0X4LnRnTdyy~RxqJukErj3dvsGP7bk7ZASD~jpmdKGtTWQvMyMqTXsERojhouuAuSiaY4ne8g0~~A3oeWr5ev11qu7wc2dCxuctcgzP9F~54YX0bl16Z9UtSAF35eABAH7ggomcQwID8cLbCJyWLwXxF5W1ReCpR2SAwFaWVPtUBoMHaT7RjHVZMDJrg1FiQI6soRh-vc7CGKBjcQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA);
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 120vh;
  display: grid;
  place-items: center;
}

.trans {
  background-color: rgba(235, 233, 233, 0.671);
  width: 80%;
  height: 85%;
  border-radius: 5px;
  backdrop-filter: blur(4px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.head {
}

.ed {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: capitalize;
  font-size: 0.8rem;
  padding: 1rem;
}
.ed i {
  padding-right: 1rem;
  font-size: 1.2rem;
}

.ed i:hover {
  color: #f58320;
}

.head h2 {
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  color: #454545;
}
.user {
  position: relative;
}
.user img {
  width: 7rem;
  margin-top: 1.2rem;
}

.user i {
  position: absolute;
  top: 6.2rem;
  left: 32.8rem;
  font-size: 1rem;
  border: 1px solid orange;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #f58320;
}
.input {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
}

.input label {
  font-family: "Ubuntu", sans-serif;
  color: #454545;
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.input input {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 5px 10px;
  font-size: 1.2rem;
  border-bottom: 1px solid #454545;
}

.tick {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 2.5rem;
  font-family: "Ubuntu", sans-serif;
  color: #454545;
}

.tick a {
  color: #f58320;
}

.tick input[type="checkbox"] {
  /* appearance: none; */
  height: 1rem;
  width: 1rem;
  background-color: transparent;
  /* border: 2px solid #f58320; */
  margin: 0 1rem 0 0;
}

.tick p {
  font-size: 1.1rem;
  font-weight: 500;
}

.trans .btn {
  border: 1px solid transparent;
  background-color: #f58320;
  color: aliceblue;
  font-weight: 400;
  text-transform: capitalize;
  padding: 0.5rem 0.8rem;
  font-size: 1.2rem;
  letter-spacing: 0.3px;
  border-radius: 5px;
  margin-top: 2.5rem;
}

.trans .btn:hover {
  background-color: white;
  color: #f58320;
  border: 1px solid #f58320;
}

@media screen and (max-width: 600px) {
}
