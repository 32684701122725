.wallet_landing {
  margin-top: 120px;
  min-height: 40vh;
}

.wallet_landing h2 {
  font-size: 1.2rem;
  width: 50vw;
  margin: auto;
  font-weight: bold;
  text-align: start;
  margin-bottom: 20px;
  color: #f58320;
}

.wallet_landing_link {
  color: #f58320;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  margin: auto;
  gap: 10px;
  font-size: 24px;
}

.wallet_landing_ctas {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.wallet_landing_items .wallet_landing_button {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  background: transparent;
  border: 1.2px solid transparent;
  border-color: #f58320;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  width: 150px;
  height: 150px;
}

.wallet__pdf__download__btn {
  display: grid;
  place-items: center;
  padding: 8px 16px;
  background: #f58320;
  color: white;
  width: max-content;
  color: white;
  border-radius: 6px;
  margin: auto;
  margin-bottom: 30px;
  font-size: 1.2rem;
}

.wallet_landing_items .wallet_landing_button:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  border-color: transparent;
}

.wallet_landing_items .wallet_landing_button .download_for {
  font-weight: normal;
  font-size: 1em;
  margin-top: 15px;
  opacity: 0.75;
}

.wallet_landing_items .wallet_landing_button .download_platform {
  font-size: 1.2rem;
  font-weight: medium;
  margin-top: 8px;
}

.download__coming_soon {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  z-index: 10;
  backdrop-filter: blur(1px);
  text-align: center;
  font-weight: bold;
  color: white;
  font-size: 1.2rem;
  text-transform: uppercase;
}
