#singlePost {
  flex: 9;
  background: #f0f0f0;
}

.singlePostWrapper {
  padding: 0px 30px;
}

#singlePost h3 {
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  padding-left: 50px;
}

#singlePost .singlePostWrapper h2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 3.5rem;
  margin-top: 100px;
}

#singlePost .singlePostWrapper .date {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.563rem;
  line-height: 29px;
}

#singlePost .singlePostWrapper .b1 {
  margin-top: 67px;
  width: 870.5px;
  height: 525.5px;
}

#singlePost .b1 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  background-color: #f58320;
}

#singlePost .singlePostWrapper .b1 img {
  width: 500px;
  height: 100%;
  object-fit: cover;
}

#singlePost .singlePostWrapper .desc {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  text-align: justify;
  
}
