@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&family=Ubuntu:wght@300;400;500&display=swap");

.body {
  background-image: url(https://s3-alpha-sig.figma.com/img/edfe/f956/146f3bd939833a9b36ba132be75f5568?Expires=1646611200&Signature=Vel5DWt~OlIUAQNTBnPK5LFHGfpN~SQ8FVXooNVRiLIBOkM8DWQVk4Qm4eCb6KyuH8brz1cteiK9-ew~1sfE2aARuWlz99FlzEPW9VucipM7tH6J1J8nP8-ImfDPaIB-xZEBSYgWv4duyTsY8YW5MsRWLcDdSyAFICnEkh6npDCCmdL7sQBeiv~tFUu~QWeuzmWYSFeh3aOb062RsfocofjJx~5oHD8MCtms9sG3fUeky9GsU2YorrQ2ekaACB9A9q30PdNqKyXYl8qVexEqTLFUEH8yIrV0EQcfotED22qYGiFNLkvQMtdKADWs8bix0jh8J7i-In7XoTg5bb~uMg__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA);
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
  margin-top: 10vh;
}

.trans {
  background-color: rgba(235, 233, 233, 0.671);
  width: 80%;
  height: 90%;
  display: grid;
  place-items: center;
  border-radius: 5px;
  backdrop-filter: blur(4px);
}
.st {
  font-size: 0.8rem;
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.st .st_image {
  width: 30vw;
}

.st img {
  width: 100%;
}
.st p {
  padding-top: 1rem;
  font-weight: 400;
  font-size: 1.2rem;
  color: black;
  letter-spacing: 0.2px;
  font-family: "Ubuntu", sans-serif;
}
.btnlog {
  margin-top: 4rem;
}
.btnlog a {
  border: 1px solid transparent;
  background-color: #f58320;
  color: aliceblue;
  font-weight: 400;
  text-transform: capitalize;
  padding: 0.5rem 0.8rem;
  font-size: 1.2rem;
  letter-spacing: 0.3px;
  border-radius: 5px;
  width: 5rem !important;
}
.btnlog p {
  padding: 1rem;
  color: #f58320;
  font-size: 1.5rem;
}

.btnlog a:hover {
  background-color: white;
  color: #f58320;
  border: 1px solid #f58320;
}

@media screen and (max-width: 600px) {
  .st p {
    font-size: 16px;
  }
}
